import React, { useEffect, useState } from "react";
import "./About.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import about from "../../Assets/Images/About/about.png";
import client1 from "../../Assets/Images/About/client1.png";
import client2 from "../../Assets/Images/About/client2.png";
import client3 from "../../Assets/Images/About/client3.png";
import client4 from "../../Assets/Images/About/client4.png";
import client5 from "../../Assets/Images/About/client5.png";
import client6 from "../../Assets/Images/About/client6.png";
import Rajivgandi from "../../Assets/Images/About/Rajivgandi.png";
import ananta from "../../Assets/Images/About/ananta.png";
import nims from "../../Assets/Images/About/nims.png";
import starimg from "../../Assets/Images/About/starimg.svg";
import background from "../../Assets/Images/About/background.png";
import Slider from "react-slick";

const cardData = [
  {
    image: client1,
    content: {
      title: "BGS Global Institute Of Medical Science,",
      location: "Bangalore",
    },
  },
  {
    image: client2,
    content: {
      title: "L.N. Medical College & J.K. Hospital Bhopal",
      location: "Bhopal",
    },
  },
  {
    image: client3,
    content: {
      title: "Adhichunchanagiri Institute Of Medical Science ",
      location: "B.G. Nagara",
    },
  },
  {
    image: client4,
    content: {
      title: "Sapthagiri Institute of Medical Science & Research Center, ",
      location: "Bangalore",
    },
  },
  {
    image: client5,
    content: {
      title: "ESIC Medical College And Hospital Rajajinagar,",
      location: " Bangalore",
    },
  },
  {
    image: client6,
    content: {
      title: "Shri Jayadeva Institute of Cardiovascular Science And Research, ",
      location: "Bangalore",
    },
  },
  {
    image: Rajivgandi,
    content: {
      title: "Rajiv Gandhi Institute Of Health Science, ",
      location: "Bangalore",
    },
  },
  {
    image: ananta,
    content: {
      title: "Ananta Institute Of Medical Science And Research Center, ",
      location: "Udaipur",
    },
  },
  {
    image: nims,
    content: {
      title: "NIMS University Rajasthan, ",
      location: "Jaipur",
    },
  },
];

const About = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCardSlide = () => {
    setActiveIndex(
      (prevActiveIndex) => (prevActiveIndex + 1) % cardData.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(handleCardSlide, 3000);

    return () => clearInterval(intervalId);
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="about">
      <br/>
      <br/>
      <br/>
      <section className=" container main-screen-about">
        <div>
          <img src={about} className="about-img" />
        </div>
        <div className="about-text">About US</div>
        <div className="text-zlip">
          Zlipp, is an advanced digital center for medical academics, a
          pioneering digital platform for visual learning and teaching in
          medical education through 3D stereoscopic, high-defined medical
          lecture content. It transforms medical learning by centralizing
          academic activities and delivering immersive learning experiences
          through an extensive 3-dimensional stereoscopic medical lecture
          content Designed
        </div>
        <div className="container second-text">
          to support Horizontal and vertical integration. Zlipp redefines the
          academic experience by centralizing all activities on a single
          platform, ensuring seamless communication and efficient management.
          <br />
          It transforms classrooms into dynamic, interactive spaces where
          lectures are automatically recorded and securely stored in a 24/7
          accessible digital library, enabling students and educators to revisit
          critical concepts anytime and to build their own lectures
          respectively. The platform goes beyond just lecture delivery,
          incorporating AI-powered tools for generating assignments,
          assessments, and academic reports based on specific topics or
          objectives. These features simplify the teaching journey of medical
          professors while ensuring detailed performance tracking and feedback.
        </div>
      </section>
      <div className="background-curosal">
        <div>
          <img src={background} className="background-img-carousel" />
        </div>
        <section className="inline-carousel">
          <div>
            <h2 className="our-clients-text">
              <b>Our Clients</b>
            </h2>
          </div>
          <div className="polygon">
            <img src={starimg} className="polygon-img" />
          </div>
          <br />

          <Slider {...settings}>
            {cardData.map((card, index) => (
              <div key={index} className="card">
                <div className="card-body">
                  <div className="card-image">
                    <img
                      src={card.image}
                      className="client-image"
                      alt={`Client ${index + 1}`}
                    />
                  </div>
                  <div className="card-content">
                    <b>{card.content.title}</b>
                    <b>{card.content.location}</b>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default About;
