import React, { useState } from "react";
import mainScreen from "../../Assets/Images/Home/mainScreen_icon.png";
import zlippIcon from "../../Assets/Images/Home/Zlipp-icon.png";
import tagSvgrepo from "../../Assets/Images/Home/tag-svgrepo.png";
import appIcons from "../../Assets/Images/Home/App-icons.png";
import Innovations from "../../Assets/Images/Home/Innovations.png";
import tristar from "../../Assets/Images/Home/tristar-img.svg";
import collegemain from "../../Assets/Images/Home/collegemain-img.png";
import Hodmain from "../../Assets/Images/Home/Hodmain-img.png";
import Educatormain from "../../Assets/Images/Home/Educatormain-img.png";
import studentmain from "../../Assets/Images/Home/studentmain-img.png";
import collegeboxIcon from "../../Assets/Images/Home/collegeboxIcon.svg";
import hodboxIcon from "../../Assets/Images/Home/hodboxIcon.svg";
import educatorboxIcon from "../../Assets/Images/Home/educatorboxIcon.svg";
import studentboxIcon from "../../Assets/Images/Home/studentboxIcon.svg";
import pvclass from "../../Assets/Images/Home/pvclass-img.svg";
import examEvaluation from "../../Assets/Images/Home/examEvaluation-img.svg";
import Announcement from "../../Assets/Images/Home/Announcement-img.svg";
import liveforum from "../../Assets/Images/Home/liveforum-img.svg";
import note from "../../Assets/Images/Home/note-icon.svg";
import attendance from "../../Assets/Images/Home/attendance-icon.svg";
import notebook from "../../Assets/Images/Home/notebook-icon.svg";
import internet from "../../Assets/Images/Home/internet-svgrepo-com.png";
import phone from "../../Assets/Images/Home/phone-svgrepo-com.svg";
import email from "../../Assets/Images/Home/email-icon.svg";
import location from "../../Assets/Images/Home/location-on.svg";
import contactus from "../../Assets/Images/Home/contactus-bgicon.png";
import blankbg from "../../Assets/Images/Home/blankbg-icon.svg";
import appStore from "../../Assets/Images/Home/app-store.png";
import playStore from "../../Assets/Images/Home/play-store.png";

import "./Home.scss";
const Home = () => (
  <>
    <section className="hero">
      <div className="home-screen-section">
        <div className="row">
          <div className="col-sm-6 col-xl-5 col-lg-5    d-flex justify-content-center">
            <div className="container right-content">
              <img
                src={zlippIcon}
                className="zlippicon zlipp-main-img"
                alt="zlippIcon"
              />
              <div className="tag-svgrepo-box">
                <p className="tag-svgrepo-text">
                  Zlipp: Where Medical Excellence <br /> Meets Revolutionary
                  Innovation!
                </p>
              </div>
              <p className="sub-text">
                Zlipp is accessible across all platforms
              </p>
              <div className="appstore-googleplay-endline">
                <p className="mini-sub-text">Download the app today.</p>
              </div>
              <div className="store-icons-box">
                <a
                  href=" https://apps.apple.com/in/app/zlipp/id6471843198"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStore} className="img-fluid appstore-icon" />
                </a>
                <a
                  href=" https://play.google.com/store/apps/details?id=com.zlipp&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={playStore} className="img-fluid playstore-icon" />
                </a>
              </div>
              <div className="home-sub-text">
                <h4 className="or-text">OR</h4>
                <p className="explore-text">You can access from Web</p>
              </div>
              <div className="btn-boxs">
                <button className="explore-web-btn">
                  <img src={internet} className="internet-svgrepo-com" />
                  <a
                    href="https://lms.zlipp.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="explore-web-text">Explore Web</span>
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-7 col-lg-7 d-flex justify-content-center">
            <div>
              <img
                src={mainScreen}
                className="img-fluid mainscreen-img"
                alt="mainScreen"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="zlipp-text-section">
      <div className="row">
        <div className="zlipp-bg">
          <div className="container">
            <div className="second-section">
              <h3>
                <b>What Is Zlipp?</b>
              </h3>
              <p>
                Zlipp, is an advanced digital center for medical academics, a
                pioneering digital platform for visual learning and teaching in
                medical education through 3D stereoscopic, high-defined medical
                lecture content. It transforms medical learning by centralizing
                academic activities and delivering immersive learning
                experiences through an extensive 3-dimensional stereoscopic
                medical lecture content Designed to support Horizontal and
                vertical integration. Zlipp redefines the academic experience by
                centralizing all activities on a single platform, ensuring
                seamless communication and efficient management.
              </p>
              <button >
                <a href="/about" style={{color:"black", fontWeight:'bold'}}>Read More &rarr;</a>
              </button>
            </div>
          </div>
        </div>
        <div className="second-section-mbl">
          <h1 className="zlipp-heading">Zlipp</h1>
          <p>
            Zlipp is pleased to announce that it is the first company in the
            Medical Education Industry to implement digital transformation.
            Zlipp has catered to the needs of the medical industry in such a way
            that we can teach and learn from anywhere. Zlipp also unveils
            pre-built and increasingly popular 3D animated audio/vision content
            for professors & students, which helps researchers understand the
            theoretical concepts through visualization that maximizes their
            knowledge.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <h1 className="Unique">
          Unique <br />
          Innovations
        </h1>
        <img src={Innovations} className="innovations" />
      </div>
    </section>

    <section className="bg-accessible">
      <div className="excellence-card">
        <h2 className="accessible-text">Accessible Excellence</h2>
        <img src={tristar} className="tristar-img" />
      </div>
      <div className="container">
        <div className="row">
          <div className="four-box-content">
            <div class="col-sm-3">
              <div class="effect-image">
                <img src={collegemain} className="college-bg-icon" />
                <div class="simple-text">
                  <p>
                    <img src={collegeboxIcon} className="collegemain-boxicon" />
                    <span>College</span>
                  </p>
                </div>
                <div class="overlay-sim-text overlay-xs-1">
                  <p>
                    <img src={collegeboxIcon} className="collegemain-boxicon" />
                    <span className="collage-head-line">College</span>
                    <p className="collage-inside-text">
                    Streamline day-to-day academic activities with progress tracking, monitoring, and notifications, and maintain a comprehensive academic history for both professors and students, ensuring continuous oversight and timely updates.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="effect-image">
                <img src={Hodmain} className="hod-bg-icon" />
                <div class="simple-text">
                  <p>
                    <img src={hodboxIcon} className="Hodmain-boxicon" />
                    <span>HOD</span>
                  </p>
                </div>
                <div class="overlay-sim-text overlay-xs-1">
                  <p>
                    <img src={hodboxIcon} className="Hodmain-boxicon" />
                    <span className="collage-head-line">HOD</span>
                    <p className="collage-inside-text">
                    Efficiently monitor, track, and evaluate departmental activities, adhering to the institutional academic course calendars, overseeing course progress with ease, and maintaining a digital logbook for both professors and students.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="effect-image">
                <img src={Educatormain} className="educator-bg-icon" />
                <div class="simple-text">
                  <p>
                    <img
                      src={educatorboxIcon}
                      className="Educatormain-boxicon"
                    />
                    <span>EDUCATOR</span>
                  </p>
                </div>
                <div class="overlay-sim-text overlay-xs-1">
                  <p>
                    <img
                      src={educatorboxIcon}
                      className="Educatormain-boxicon"
                    />
                    <span className="collage-head-line">EDUCATOR</span>
                    <p className="collage-inside-text">
                    Allow to develop their own medical lecture content as an audio-lecture file, with Digital assignment, assessment, and class-wise and lecture-wise attendance in one platform where they can develop and manage content, and track student progress with ease.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="effect-image">
                <img src={studentmain} className="student-bg-icon" />
                <div class="simple-text">
                  <p>
                    <img src={studentboxIcon} className="studentmain-boxicon" />
                    <span>STUDENT</span>
                  </p>
                </div>
                <div class="overlay-sim-text overlay-xs-1">
                  <p>
                    <img src={studentboxIcon} className="studentmain-boxicon" />
                    <span className="collage-head-line">STUDENT</span>
                    <p className="collage-inside-text">
                    24*7 access all digital resources, stay updated on academic activities and engage in 3-dimensional audio-visual topic-wise medical lecture content, medical learning, and skill learning.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="Main-overview">
      <div className="container">
        <div className="overview-card">
          <h2 className="overview-text">Overview</h2>
          <img src={tristar} className="tristar" />
        </div>
      </div>
    </section>
    <section className="box-content">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="box-card">
              <img src={pvclass} className="" />
              <p class="card-text">Physical Class / Virtual Class.</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-card">
              <img src={examEvaluation} className="" />
              <p class="card-text">Exam And Evaluation</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-card">
              <img src={Announcement} className="" />
              <p class="card-text">Announcement.</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-card">
              <img src={liveforum} className="" />
              <p className="card-text">Live Forum For Interaction.</p>
            </div>
          </div>
          <div className="card-box-reverse"></div>
          <div className="col-md-3">
            <div className="card-box">
              <img src={note} className="" />
              <p className="card-text">AI Assignment & Assessment</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card-box">
              <img src={attendance} className="" />
              <p className="card-text">
                Classroom Teaching Tool With Attendance.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card-box">
              <img src={notebook} className="" />
              <p className="card-text">Competency Based Management System.</p>
            </div>
          </div>
          <div className="card-box-curve"></div>/
        </div>
      </div>
    </section>

    <section className="main-box">
      <img src={blankbg} className="blankbg-icon" />
      <div className="container">
        <div className="row">
          <div className="center-text">
            <hr className="hr-line"></hr>
            <span className="dot5"></span>
            Don’t Just Take Our Word For It!
            <span className="dot6"></span>
            <hr className="hr-line"></hr>
          </div>
          <div className="outside-border">
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <span className="dot1">
                <div className="main-number">
                  10+
                  <p className="under-text">Course</p>
                </div>
              </span>
            </div>
            <div className="col-md-2">
              <span className="dot2">
                <div className="main-number">
                  20+
                  <p className="under-text">Colleges</p>
                </div>
              </span>
            </div>
            <div className="col-md-2">
              <span className="dot3">
                <div className="main-number">
                  5k+
                  <p className="under-text">Educators</p>
                </div>
              </span>
            </div>
            <div className="col-md-2">
              <span className="dot4">
                <div className="main-number">
                  10k+
                  <p className="under-text">Students</p>
                </div>
              </span>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    </section>

    <section className="contact-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="contactus-leftside-content">
              <h1> Let’s Connect</h1>
              <h5>Get in touch and let us know how we can help you</h5>
              <div className="conatctus-card-text">
                <span>
                  <img src={phone} className="phone-icon" />
                </span>
                <span className="contact-number">+91 6366222495</span>
              </div>
              <div className="conatctus-card-text">
                <span>
                  <img src={email} className="email-icon" />
                </span>
                <span className="contact-number">contact@zlipp.in</span>
              </div>
              <div className="conatctus-card-text">
                <span>
                  <img src={location} className="location-icon" />
                </span>
                <span className="contact-number">
                  {" "}
                  Amci EdTech PVT. Ltd. <br />
                  4th-floor, SJBIT, CSE & ISE Block,
                  <br />
                  Dr.Vishnuvardhan Rd, Kengeri, <br />
                  Bengaluru, Karnataka 560060
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="form-table">
              <div className="heading-from-text">Fill the form below</div>
              <div className="row">
                <div className="col-md-6">
                  <div className="contactus-name-box">
                    <label>Name*</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contactus-name-box">
                    <label>Email*</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-12 pt-3">
                  <div className="contactus-name-box">
                    <label>Message</label>
                    <textarea type="text" />
                  </div>
                </div>
              </div>
              <div className="contactus-btn">
                <button>Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Home;
